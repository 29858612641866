export const OB = {
  id: '333643',
  name: 'OB',
  variants: ['30616'],
  variantOpenBanking: '30616',
};

export const PPT = {
  id: '343023',
  name: 'PPT enable',
  variants: ['44284'],
  variantPPTDisabled: '44284',
};

export const CUCUTA = {
  id: '344221',
  name: 'CUCUTA enable',
  46199: ['46199'],
  cucutaEnable: '46199',
};

export const INCREASE_CLARITY = {
  id: '342751',
  name: 'Increase Clarity on Product Choice',
  variants: ['43891', '43892'],
  control: '43891',
  treatment: '43892',
};

export const PURPLE_EFFECT = {
  id: '348001',
  name: 'Efecto Morado Marketing Campaign',
  variants: ['47476', '47477'],
  control: '47476',
  treatment: '47477',
};
